import React, { useEffect, useState } from "react";
import { View,StyleSheet,RefreshControl, ScrollView } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Layout,
  TopNav,
  Text,
  themeColor,
  useTheme,
  Section,
  SectionContent,
  Button,
  TextInput,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { supabase } from "../initSupabase";
import { Session } from "@supabase/supabase-js";


export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "Cart">) {
  const { isDarkmode, setTheme } = useTheme();
  const [fetchError, setFetchError] = useState(null)
  const [cart, setCart] = useState([])
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<null | boolean>(null);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
		const session = supabase.auth.session();
		setSession(session);
		setUser(session ? true : false);
		const { data: authListener } = supabase.auth.onAuthStateChange(
			async (event, session) => {
				console.log(`Supabase auth event: ${event}`);
				setSession(session);
				setUser(session ? true : false);
			}
		);
		return () => {
			authListener!.unsubscribe();
		};
	}, [user]);

  useEffect(()=>{
    const fetchCart= async () => { 
    const {data, error} = await supabase
      .from('cart')
      .select()
      .eq('email',session?.user?.email)
      if (error?.message){ 
        setFetchError(null)
        setCart(null)
      } 
      if (data){
        setCart(data)
        setFetchError(null)
      }
    }
    fetchCart()
    }, [session?.user?.email])

    const Remove_item = async (carts:any) => {
      setLoading(true)
      const { data,error } = await supabase
      .from('cart')
      .delete()
      .eq('id',  carts.id)
    if (error) alert(error.message)
      setLoading(false)
    if(data){
      console.log(data)
      alert("Removed")
      navigation.replace("Cart");
    }
    } 

    const cartQuantity = cart.length;


    let total = 0;
    let subtotal = 0;
    const Total = cart.map((carts:any) =>
    total += carts.price * carts.quantity)
    const subTotal = cart.map((carts:any) => 
    subtotal += carts.Item_total * cartQuantity)
    const [refreshing, setRefreshing] = React.useState(false);

   

  return (
    <Layout>
      <ScrollView
      >
      <TopNav
        middleContent="Your Cart" 
        leftContent={
          <Ionicons
            name="chevron-back"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.dark}
        
          />
        }
        leftAction={() => navigation.goBack()}
      />
      <View  style={{flex: 1,}}>
       <Section>
                    {cart && ( 
                    <SectionContent>
                        {cart.map((carts:any) => (
                          <Section  key={carts.id}> 
                              <SectionContent style={ styles.container}>
                                <Text size='xl' >{carts.name}</Text>
                                <Text size='lg'>₱ {carts.price}</Text>
                                <Text size='md' >x{carts.quantity}</Text>
                                <Text size='lg' >Total: {carts.Item_total}</Text>
                              </SectionContent>
                             
                             <Button text="Remove" color='#FFD700' style={{ marginBottom: 20 }} onPress={()=> (Remove_item(carts))}
                              textStyle={{ color: "black", textTransform: "uppercase", // Set text to uppercase
                            }}/>
                          
                          </Section>
                        ))}
                  </SectionContent> )}
                  </Section>        
        </View>
        </ScrollView>
        <Text size='sm' style={{alignSelf:"center", marginBottom:5}}> Note: If you don't see your items just refresh the page </Text>
        <SectionContent style={styles.container}>
                <Text size='xl' >Total Items: {cartQuantity}</Text>
                <Text size='xl' > Sub Total: ₱ {parseFloat(total)} </Text>
                </SectionContent>                    
                <Button text="Check out" color='#FFD700'  onPress={()=> {navigation.navigate("CheckOut");}} 
                    textStyle={{ color: "black", textTransform: "uppercase", // Set text to uppercase
                  }}/>
    </Layout>
  );
}
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#EEEEEE',
    marginBottom: 10,
  },
  
});
