import React, { useEffect, useState } from "react";
import {
  View,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  LogBox,
  ImageBackground
} from "react-native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { supabase } from "../initSupabase";
import {
  Layout,
  Text,
  TopNav,
  Section,
  SectionContent,
  SectionImage,
  useTheme,
  themeColor,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";

import _ from "lodash";

LogBox.ignoreLogs(["Warning:..."]); // Ignore specific logs
LogBox.ignoreAllLogs(); // Ignore all logs
const _console = _.clone(console);
console.warn = (message) => {
  if (message.indexOf("Setting a timer") <= -1) {
    _console.warn(message);
  }
};

export default function HomeScreen({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MainTabs">) {
  const { isDarkmode } = useTheme();
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [menu, setMenu] = useState(null);
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<null | boolean>(null);

  useEffect(() => {
    const fetchMenu = async () => {
      const { data, error } = await supabase
        .from("Menu")
        .select()
        .eq("availability", "Available");
      if (error) {
        setFetchError(null);
        setMenu(null);
      }
      if (data) {
        setMenu(data);
        setFetchError(null);
      }
    };

    const initAuth = () => {
      const session = supabase.auth.session();
      setSession(session);
      setUser(session ? true : false);
      const { data: authListener } = supabase.auth.onAuthStateChange(
        async (event, session) => {
          console.log(`Supabase auth event: ${event}`);
          setSession(session);
          setUser(session ? true : false);
        }
      );
      return () => {
        authListener!.unsubscribe();
      };
    };

    fetchMenu();
    initAuth();
  }, []);

  const menu1 = menu ? menu.slice(0, Math.ceil(menu.length / 2)) : [];
  const menu2 = menu ? menu.slice(Math.ceil(menu.length / 2)) : [];

  return (
    <Layout>
      <ScrollView>
      <TopNav style={styles.topnav}
        middleContent="H O M E"
        rightContent={ 
          <Ionicons
          name="cart"
          size={24}
          color={isDarkmode ? themeColor.white100 : themeColor.dark}
          />
         }
        rightAction={() => {
          navigation.navigate("Cart");
        }}
        />
      
        <ImageBackground
              source={require("../../assets/images/seafoodtwo.jpg")}
              style={styles.backgroundImage}
              >
        <View style={styles.container}>
          {menu && (
            <Section>
              <ImageBackground
              source={require("../../assets/images/seafoodtwo.jpg")}
              style={styles.backgroundImage}
              >
              <SectionContent>
                <View style={styles.row}>
                  <View style={styles.column}>
                    {menu1.map((menuItem: any) => (
                      <TouchableOpacity
                        key={menuItem.id}
                        onPress={() => {
                          navigation.navigate("CartItem", {
                            ItemID: menuItem.id,
                          });
                        }}
                      >
                        <View style={styles.cardContainer}>
                          <SectionImage
                            source={menuItem.image}
                            style={styles.sectionImage}
                          />
                          <View style={styles.singleItem}>
                            <Text size="2xl">{menuItem.menu_name}</Text>
                            <Text size="lg">₱ {menuItem.price}</Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    ))}
                  </View>
                  <View style={styles.column}>
                    {menu2.map((menuItem: any) => (
                      <TouchableOpacity
                        key={menuItem.id}
                        onPress={() => {
                          navigation.navigate("CartItem", {
                            ItemID: menuItem.id,
                          });
                        }}
                      >
                        <View style={styles.cardContainer}>
                          <SectionImage
                            source={menuItem.image}
                            style={styles.sectionImage}
                          />
                          <View style={styles.singleItem}>
                            <Text size="2xl">{menuItem.menu_name}</Text>
                            <Text size="lg">₱ {menuItem.price}</Text>
                          </View>
                        </View>
                      </TouchableOpacity>
                    ))}
                  </View>
                </View>
              </SectionContent>
              </ImageBackground>
            </Section>
          )}
        </View>
        </ImageBackground>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    marginLeft: 20,
    marginRight: 20
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-evenly", // Center the columns
    marginBottom: 20,
  },
  column: {
    flex: 1,
    marginHorizontal: 5, // Added margin for equal spacing
  },
  topnav: {
    backgroundColor: "#FFD700"
  },
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
    alignItems: "center",
  },
  cardContainer: {
    borderWidth: 5,
    borderColor: "#FFD700",
    backgroundColor: "#FFD700", // Change to your card background color
    borderRadius: 10,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    marginBottom: 10,
  },
  singleItem: {
    textAlign: "center",
    fontWeight: "bold",
    alignItems: "center",
    padding: 10,
    fontFamily: 'Century Gothic',
    
  },
  sectionImage: {
    alignContent: "center",
    width: "80%", // Set the desired width to 100%
    height: 300, // Set the desired height (larger)
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
});
