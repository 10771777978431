import React, { useEffect, useState } from "react";
import { Linking, View, StyleSheet, TouchableOpacity, ImageBackground, Text as RNText, Dimensions } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Button, Layout, Section, SectionContent, Text, TopNav, themeColor, useTheme } from "react-native-rapi-ui";
import { supabase } from "../initSupabase";
import { Session } from "@supabase/supabase-js";
import { Ionicons, Entypo } from "@expo/vector-icons";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MainTabs">) {
  const [loading, setLoading] = useState(true);
  const { isDarkmode, setTheme } = useTheme();
  const [fetchError, setFetchError] = useState(null);
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<null | boolean>(null);

  useEffect(() => {
    const session = supabase.auth.session();
    setSession(session);
    setUser(session ? true : false);
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log(`Supabase auth event: ${event}`);
        setSession(session);
        setUser(session ? true : false);
      }
    );
    return () => {
      authListener!.unsubscribe();
    };
  }, [user]);

  const [USERNAME, setUSERNAME] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      const { data, error } = await supabase
        .from("profiles")
        .select()
        .eq("email", session?.user?.email)
        .single();
      if (error?.message === "Record not found") {
        setFetchError(null);
        setUSERNAME(null);
      }
      if (data) {
        setUSERNAME(data.username);
        setFetchError(null);
      }
    };
    fetchProfile();
  }, [session?.user?.email]);

  return (
    <Layout>
      <ImageBackground
        source={require("../../assets/images/seafoodtwo.jpg")}
        style={styles.backgroundImage}
      >
      <View style={styles.container}>
        <View style={styles.card}>
          <SectionContent>
            <Text fontWeight="bold" size="xl" style={{color: "white" }}>
              {USERNAME}
            </Text>
            <Text fontWeight="regular" style={{color: "white"}}>Personal Account</Text>

            <Button
              leftContent={<Ionicons name="person" size={20} color={isDarkmode ? themeColor.white100 : themeColor.dark} />}
              text="Edit Profile"
              inverseColor="black"
              type="TouchableOpacity"
              color="#FFD700"
              
              onPress={() => {
                navigation.navigate("Edit_Profile");
              }}
              style={{ marginTop: 10, }}
            />
            <Button
              leftContent={<Entypo name="location-pin" size={20} color={isDarkmode ? themeColor.white100 : themeColor.dark} />}
              text="Address"
              inverseColor="black"
              type="TouchableOpacity"
              color="#FFD700"
              onPress={() => {
                navigation.navigate("Addresses");
              }}
              style={{ marginTop: 10 }}
            />
            <Button
              leftContent={<Ionicons name="reorder-four" size={20} color={isDarkmode ? themeColor.white100 : themeColor.dark} />}
              text="My Orders"
              inverseColor="black"
              type="TouchableOpacity"
              color="#FFD700"
              onPress={() => {
                navigation.navigate("MyOrders");
              }}
              style={{ marginTop: 10 }}
            />

            <Button
              text="Logout"
              inverseColor="black"
              type="TouchableOpacity"
              color="#FFD700"
              onPress={async () => {
                const { error } = await supabase.auth.signOut();
                if (!error) {
                  alert("Signed out!");
                }
                if (error) {
                  alert(error.message);
                }
              }}
              style={{
                marginTop: 10,
              }}
            />
          </SectionContent>
        </View>
      </View>
      </ImageBackground>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center", // Center horizontally
    justifyContent: "center", // Center vertically

  },
  
  backgroundImage: {
    flex: 1,
    resizeMode: "cover",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    borderWidth: 10,
    borderColor: "#FFD700",
    borderRadius: 10, // Border radius for the card
    shadowColor: "black", // Shadow color
     width: "100%",
    shadowOffset: {
      width: 10, // Increase the width to make the card larger
      height: 5, // Increase the height to make the card larger
    },
    shadowOpacity: 0.25, // Shadow opacity
    shadowRadius: 5, // Shadow radius
    elevation: 5, // Android shadow elevation
    marginBottom: 10,
    padding: 20, // Increase the padding to make the card larger
    alignItems: "center", // Center card content horizontally
  },
});
