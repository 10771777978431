import React, { useEffect, useState } from "react";
import {   View,StyleSheet, Dimensions, ScrollView } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Layout,
  TopNav,
  Text,
  themeColor,
  useTheme,
  Section,
  SectionContent,Button,TextInput, Picker, CheckBox, RadioButton, SectionImage,
} from "react-native-rapi-ui";
import { Entypo, Ionicons, MaterialIcons } from "@expo/vector-icons";
import { SafeAreaView } from "react-native-safe-area-context";
import { Session } from '@supabase/supabase-js';
import { supabase } from "../initSupabase";
import Loading from "./utils/Loading";
import { set } from "lodash";


const { width } = Dimensions.get('window');
const gap = 12;
const itemPerRow = 3;
const totalGapSize = (itemPerRow - 1) * gap;
const windowWidth = width;
const childWidth = (windowWidth - totalGapSize) / itemPerRow;


export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "CheckOut">) {
  const { isDarkmode, setTheme } = useTheme();
  const [fetchError, setFetchError] = useState(null)
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<null | boolean>(null);
  const [loading, setLoading] = useState(true)
  const [cart, setCart] = useState([])
  const [Address, setAddress] = useState([])
  const [USERNAME, setUSERNAME] = useState(null)
  const [PNUMBER, setPNUNMER] = useState(null)
  const [Status, setStatus] = useState("Pending")  
  const [orderID, setOrderID] = useState(null)
  const [checkBox, setCheckBox] = useState(false)
  const [GREFNO , setGREFNO ] = useState(null)

  useEffect(() => {
		const session = supabase.auth.session();
		setSession(session);
		setUser(session ? true : false);
		const { data: authListener } = supabase.auth.onAuthStateChange(
			async (event, session) => {
				console.log(`Supabase auth event: ${event}`);
				setSession(session);
				setUser(session ? true : false);
			}
		);
		return () => {
			authListener!.unsubscribe();
		};
	}, [user]);

  useEffect(()=>{
    const fetchProfile = async () => { 
    const {data, error} = await supabase
      .from('profiles')
      .select()
      .eq('email',session?.user?.email)
      .single()
      if (error?.message){ 
        setFetchError(null)
        setUSERNAME(null)
        setPNUNMER(null)
      } 
      if (data){
        setUSERNAME(data.username)
        setPNUNMER(data.P_NUMBER)
        setFetchError(null)
      }
    }
    
    fetchProfile()
    }, [session?.user?.email])

    useEffect(()=>{
        const fetchMenu = async () => { 
        const {data, error} = await supabase
          .from('cart')
          .select('name,price,quantity,Item_total')
          .eq('email',session?.user?.email)
          if (error?.message){ 
            setCart([])
          } 
          if (data){
            setCart(data)

          }
        }
        fetchMenu()
        }, [session?.user?.email])

        useEffect(()=>{
            const fetchAddress= async () => { 
            const {data, error} = await supabase
              .from('userAddresses')
              .select('street,barangay,city,province')
              .eq('email',session?.user?.email)
              if (error?.message){ 
                setAddress(null)
              } 
              if (data){
                setAddress(data)
              }
            }
            fetchAddress()
            }, [session?.user?.email])


        const cartQuantity = cart.length;
        let total = 0;
        let subtotal = 0;
        const Total = cart.map((carts:any) =>
        total += carts.price * carts.quantity)
        const subTotal = cart.map((carts:any) => 
        subtotal += carts.Item_total * cartQuantity)

        //generates random id;
          const guid = () => {
            let s4 = () => {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            }
            //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
            return s4() + s4() ;
          }

          console.log(guid());
          //"c2181edf-041b-0a61-3651-79d671fa3db7"    

      const PlaceOder = async () => {
        setLoading(true)
        const { data,error } = await supabase
        .from('Orders')
        .insert([{ orderID:guid(), name:USERNAME, number:PNUMBER,  status:Status, orders:cart, total_payment:total, payment_method:paymentOptions, address:Address,branch:branch,email:session?.user?.email,Cancellation:"Eligible",GcashRefNo:GREFNO}])
      if (error) alert(error.message)
        setLoading(false)
      if(data){
        console.log(data)
        alert("Succesfull")
      }
      }
 
      const Remove_Cart = async () => {
        setLoading(true)
        const { data,error } = await supabase
        .from('cart')
        .delete()
        .eq('email',  session?.user?.email)
      if (error) alert(error.message)
        setLoading(false)
      if(data){
        console.log(data)
      }
      } 


    const [paymentOptions, setPaymentOPtions] = useState(null);
    const items = [
        { label: 'GCash', value: 'GCash' },
        { label: 'Cash on Delivery', value: 'COD' },
    ];

    const [branch, setbranch] = useState(null);
    const items1 = [
        { label: 'Calapan Branch', value: 'Calapan' },
        { label: 'Naujan Branch', value: 'Naujan' },
    ];

    const [NAME, setNAME] = useState(null);
    const [IMAGE, setIMAGE] = useState('');
    const [DOWN, setDOWN] = useState(null);
    const [mode, setMode] = useState(null);
    const [Gcash, setGcash] = useState(null)

   const payMent = async () => {
        setLoading(true)
      const {data, error} = await supabase
        .from('payment')
        .select()
        .eq('mode',paymentOptions)
        if (error?.message){ 
         
        } 
        if (data){
          setMode(data)
          console.log(data)
        }
      }
     
    
  return (
    <Layout>
      <ScrollView>
      <TopNav
        middleContent="C H E C K  O U T"
        leftContent={
          <Ionicons
            name="chevron-back"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.dark}
          />
        }
        leftAction={() => navigation.goBack()}
      
      />
      <View style={styles.container}>
      <View style={styles.card}>
      <View style={{flex: 1,}}>
        {/* This text using ubuntu font */}
        <Section>
        {cart && ( 
        <SectionContent >
          <Text size="lg" fontWeight="bold" style={{textAlign:"center"}}> Items</Text>     
            {cart.map((carts:any) => (
              <Section  key={carts.id}> 
                  <SectionContent style={styles.Precontainer}>
                    <Text size='xl'>{carts.name}</Text>
                    {/* <Text size='md'>₱ {carts.price}</Text> */}
                   
                    <Text size='md'>Total: ₱ {carts.Item_total}</Text>
                  </SectionContent>
              </Section>
            ))}
       </SectionContent> )}
       </Section>
            <Section>
                <SectionContent style={styles.containerShipping}>
                    <Text fontWeight="bold" style={{marginBottom: 10, alignContent:'center' } }>
                      <Entypo name="location-pin" size={20}  color={isDarkmode ? themeColor.white100 : themeColor.dark} /> Delivery Address:</Text>
                    <Text fontWeight="medium" style={{ textAlign: "left" }}>
                            Name: {USERNAME}  
                          </Text>
                          <Text fontWeight="medium" style={{ textAlign: "left" }}>
                            Mobile Number: {PNUMBER}  
                          </Text>              
                </SectionContent>
              
                {Address && ( 
                    <SectionContent style={styles.containerShipping}>
                        <Text size="md" fontWeight="medium" style={{textAlign:"center"}}> Address </Text>     
                        {Address.map((adr:any) => (      
                          <Section  key={adr.id}  style={styles.containerAdrss} >      
                              <SectionContent>
                                <Text fontWeight="bold" size='md' >{adr.adrs_Name}</Text> 
                                <Text size='sm' >{adr.street}</Text>
                                <Text size='sm'>{adr.barangay}</Text>
                                <Text size='sm' >{adr.city}</Text>
                                <Text size='sm' >{adr.province}</Text>
                                
                              </SectionContent>
                             
                          </Section>
                          ))}
                        </SectionContent>  )}
                        <SectionContent   >
                            <Text fontWeight="bold" >Branch Option</Text>
                            <Picker
                              items={items1}
                              value={branch}
                              placeholder="Choose Branch"
                              onValueChange={(val) => setbranch(val)}
                          />
                        </SectionContent>
                <SectionContent  style={styles.containerShipping}>
                      <Text fontWeight="bold" style={{marginBottom: 10,alignContent:'center' }}>
                        <MaterialIcons name="payments" size={20} color={isDarkmode ? themeColor.white100 : themeColor.dark} /> Payment Details:</Text>
                            <Text fontWeight="bold" style={{ textAlign: "left" }}>
                              Total  Payment: ₱ {parseFloat(total)}
                            </Text>
                  </SectionContent>
                  <SectionContent>
                      <Text fontWeight="bold" >Payment Option</Text>
                      <Picker
                        items={items}
                        value={paymentOptions}
                        placeholder="Choose Payment Method"
                        onValueChange={(val) => setPaymentOPtions(val)}
                    />
                    <Button text="Select" textStyle={{
                            color: "black",
                            fontWeight: "bold", // Set text to bold
                            textTransform: "uppercase", // Set text to uppercase
                            }}
                            color='#FFD700'  
                            onPress={()=> payMent()}  />
                  </SectionContent>
                  {mode && ( 
                    <SectionContent >
                        {mode.map((modes:any) => (      
                          <Section  key={modes.id}  style={styles.containerAdrss}>           
                              
                                <Text fontWeight="bold" size='md' >Mode of Payment: {modes.Name}</Text> 
                                <Text fontWeight="bold" size='md' >{modes.down}</Text> 
                                <Text fontWeight="bold" size='md' > </Text> 
                                <Text fontWeight="bold" size='lg' >Pay on this Gcash Number</Text> 
                                <Text fontWeight="bold" size='xl' >+63-{modes.Gcash}</Text> 

                                {/* <SectionImage style={styles.image} source={modes.image} /> */}
                                <Text fontWeight="bold" size='md' > </Text> 
                                <Text fontWeight="bold" size='lg' > Enter Gcash Ref. No. </Text> 
                                  <TextInput 
                                  placeholder="Enter Gcash Ref. No."
                                  maxLength={13}
                                  keyboardType="numeric"
                                  value={GREFNO}
                                  onChangeText={(val) => setGREFNO(val)}/>
                          </Section>
                          ))}
                        </SectionContent>  )}
              </Section>
      </View>
      </View>
    </View>
      </ScrollView>
      <Text size='sm' style={{alignSelf:"center", marginBottom:5}}>PLEASE MAKE SURE THAT</Text>
      <Text size='sm' style={{alignSelf:"center", marginBottom:5}}>EVERYTHING IS FILLED AND CORRECT</Text>
      <Text size='sm' style={{alignSelf:"center", marginBottom:5}}>BEFORE PLACING YOUR ORDER</Text>
      <Button text="Place Order" 
                          textStyle={{
                            color: "black",
                            fontWeight: "bold", // Set text to bold
                            textTransform: "uppercase", // Set text to uppercase
                            }}color='#FFD700'  
                            onPress={()=> {PlaceOder(),Remove_Cart()}} />
    
      </Layout>
  );
}
const styles = StyleSheet.create({

  Precontainer: {
    flexDirection: "column",
    alignItems: 'center',
    backgroundColor: '#FFD700',
    marginBottom: 10,
    borderRadius: 10,
  },
  container: {
    flex: 1,
    alignItems: "center", // Center horizontally
    justifyContent: "center", // Center vertically
    
  },
  card: {
    borderWidth: 10,
    borderColor: "#FFD700",
    backgroundColor: "white", // Background color for the card
    borderRadius: 10, // Border radius for the card
    shadowColor: "black", // Shadow color
    shadowOffset: {
      width: 10, // Increase the width to make the card larger
      height: 5, // Increase the height to make the card larger
    },
    shadowOpacity: 0.25, // Shadow opacity
    shadowRadius: 5, // Shadow radius
    elevation: 5, // Android shadow elevation
    marginBottom: 50,
    marginTop: 50,
    width: "90%",
    padding: 20, // Increase the padding to make the card larger
    alignItems: "center", // Center card content horizontally
  },
  containerShipping: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: "#FFD700",
    borderRadius: 10,
    marginBottom: 10,
    textAlign: 'center',
    color: "#FFD700",
  },
  containerAdrss: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: "#FFD700",
    borderRadius: 10,
    marginBottom: 10,
    textAlign: 'center',
    color: "#FFD700",
  },
  itemsWrap: {
    marginBottom: 5,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: -(gap / 2),
    marginHorizontal: -(gap / 2),
    textAlign: "center",
  },
  image: {  
    width: childWidth,
  }


});
