import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { supabase } from "../initSupabase";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Layout,
  Button,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
  SectionImage,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";




import { LogBox } from 'react-native';
import _, { set } from 'lodash';

LogBox.ignoreLogs(['Warning:...']); // ignore specific logs
LogBox.ignoreAllLogs(); // ignore all logs
const _console = _.clone(console);
console.warn = message => {
if (message.indexOf('Setting a timer') <= -1) {
   _console.warn(message);
   }
};
export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MyOrders">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)
  const [Orders, setOrders] = useState(null)
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<null | boolean>(null);
  const [StatusR, setStatusR] = useState("Completed")
    const [Status, setStatus] = useState(false)


  useEffect(() => {
		const session = supabase.auth.session();
		setSession(session);
		setUser(session ? true : false);
		const { data: authListener } = supabase.auth.onAuthStateChange(
			async (event, session) => {
				console.log(`Supabase auth event: ${event}`);
				setSession(session);
				setUser(session ? true : false);
			}
		);
		return () => {
			authListener!.unsubscribe();
		};
	}, [user]);
  


  useEffect(()=>{
    const fetchOders= async () => { 
    const {data, error} = await supabase
      .from('Orders')
      .select()
      .eq('email', session?.user?.email)
      if (error){ 
        setFetchError(null)
        setOrders(null)
      } 
      if (data){
        setOrders(data)
        setFetchError(null)
      }
    }
    fetchOders()
    }, [session?.user?.email])


      const Update_statusR = async (menus:any) => {
        setLoading(true)
        const { data, error } = await supabase
        .from('Orders')
        .update({ status: StatusR })
        .eq('id', menus.id)
        setLoading(false)
      if(data){
        console.log(data)
        alert("Succesfull")
      }
      }


      
    
  return (
    <Layout>
      <ScrollView>
      <TopNav
        middleContent="M Y  O R D E R S"
        leftContent={
            <Ionicons
              name="chevron-back"
              size={20}
              color={isDarkmode ? themeColor.white100 : themeColor.dark}
            />
          }
          leftAction={() => navigation.goBack()}
         
      />
      <View style={{flex: 1,}}>
        <Section>
              {/* <SectionContent>
                <Button
                leftContent={<Ionicons name="person" size={20}  color={isDarkmode ? themeColor.white100 : themeColor.dark} />}
                text="   Edit Profile"
                inverseColor="black"
                type="TouchableOpacity"
                color="#EEEEEE"
                onPress={() => {navigation.navigate("Edit_Profile");}}
                style={{marginTop: 10}}
                />

              </SectionContent> */}
              {Orders && ( 

              <SectionContent >
                  {Orders.map((menus:any) => (          
                    <Section  key={menus.id}> 
                    <TouchableOpacity onPress={()=> {navigation.navigate("Cancellation",{OrderID: menus.id});}}>
                        <SectionContent style={ styles.singleItem}>
                        {/* <Text size='lg'>Ref. No: {menus.orderID}</Text> */}
                        <Text size='xl'>Name: {menus.name}</Text>
                        <Text size='lg'>Order Status: {menus.status}</Text>
                      </SectionContent>
                      </TouchableOpacity>

                        
                       {/* <Button  text="Receive" color='#ffa500' style={{marginBottom:5 }} onPress={()=> {Update_statusR(menus),click}} disabled={Status} /> */}
                       {/* <Button text="Cancel"  status="danger" style={{marginBottom:20}} onPress={()=> {navigation.navigate("Cancellation",{OrderID: menus.id});}} /> */}
                    </Section>
                  ))}
            </SectionContent> )} 
        </Section>
       
      </View>
      </ScrollView>
    </Layout>
  );
}

const styles = StyleSheet.create({
  singleItem: {
    textAlign: "center",
    flex: 1,
    flexDirection: "column",
    marginBottom: 10,
    backgroundColor: '#FFD700', // Set background color to #ffd700
    borderRadius: 10, // Add border radius of 10
    padding: 10, // Add padding for better spacing
    Width: 100,
    Height: 100,
    // flex: 1,
    alignItems: "center", // Center horizontally
    justifyContent: "center", // Center vertically
    // backgroundColor: "", // Background color for the entire screen
    // borderRadius: 10, 
  },
});
