import React, { useEffect, useState } from "react";
import { View,StyleSheet, ImageBackground} from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Layout,
  TopNav,
  Text,
  themeColor,
  useTheme,
  Section,
  SectionContent,
  TextInput,
  Button,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";
import { supabase } from "../initSupabase";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "Addresses">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<null | boolean>(null);

  useEffect(() => {
		const session = supabase.auth.session();
		setSession(session);
		setUser(session ? true : false);
		const { data: authListener } = supabase.auth.onAuthStateChange(
			async (event, session) => {
				console.log(`Supabase auth event: ${event}`);
				setSession(session);
				setUser(session ? true : false);
			}
		);
		return () => {
			authListener!.unsubscribe();
		};
	}, [user]);

  const [USERNAME, setUSERNAME] = useState(null)
  const [PNUMBER, setPNUNMER] = useState(null)

  useEffect(()=>{
    const fetchProfile = async () => { 
    const {data, error} = await supabase
      .from('profiles')
      .select()
      .eq('email',session?.user?.email)
      .single()
      if (error?.message === 'Record not found'){ 
        setUSERNAME(null)
        
      } 
      if (data){
        setUSERNAME(data.username)
        setPNUNMER(data.P_NUMBER)
      }
    }
    fetchProfile()
    }, [session?.user?.email])

    const [Address, setAddress] = useState(null)

    useEffect(()=>{
        const fetchAddress= async () => { 
        const {data, error} = await supabase
          .from('userAddresses')
          .select()
          .eq('email',session?.user?.email)
          if (error?.message){ 
            setAddress(null)
          } 
          if (data){
            setAddress(data)
          }
        }
        fetchAddress()
        }, [session?.user?.email])

        const Remove_item = async (adr:any) => {
          setLoading(true)
          const { data,error } = await supabase
          .from('userAddresses')
          .delete()
          .eq('id',  adr.id)
        if (error) alert(error.message)
          setLoading(false)
        if(data){
          console.log(data)
          alert("Removed")
        }
        } 
  return (
    <Layout>
      <TopNav
        middleContent="A D D R E S S"
        leftContent={
          <Ionicons
            name="chevron-back"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.dark}
          />
        }
        leftAction={() => navigation.goBack()}
       
      />
      <ImageBackground
        source={require("../../assets/images/seafoodtwo.jpg")}
        style={styles.backgroundImage}
      >
    <View style={styles.container}>
    <View style={styles.card}>  
     <View style={{flex: 1,}}>
        {/* This text using ubuntu font */}

        <Section style={{ marginTop: 20 }}>
          <SectionContent  style={styles.Precontainer}>
          <Text fontWeight="bold"  size="xl">
            {USERNAME}  </Text>
            <Text fontWeight="regular"> 
            Personal Account</Text>
          
                    {Address && ( 
                    <SectionContent>
                        {Address.map((adr:any) => (
                          <Section  key={adr.id}> 
                              <SectionContent style={ styles.container}>
                                <Text size='xl' style={{marginBottom:3}}>{adr.adrs_Name}</Text>
                                <Text size='md' >{adr.street}, {adr.barangay}</Text>
                                <Text size='md' >{adr.city}, {adr.province}</Text>

                                <Button textStyle={{color: "black", fontWeight: "bold", textTransform: "uppercase"}}text="Remove" status="danger" style={{ marginTop:10,}} color='#FFD700' onPress={()=> Remove_item(adr)}/>
                              </SectionContent>
                          </Section>))}
                             <Button
                                style={{ marginTop: 5 }}
                                text="ADD A Home Address"
                                status="primary"
                                size="md"
                                color="white"
                                  textStyle={{
                                    color: "black",
                                    fontWeight: "bold", // Set text to bold
                                    textTransform: "uppercase", // Set text to uppercase
                                    }}
                                onPress={() =>{navigation.navigate("Add_Address");}}
                                />
                  </SectionContent> )}
          </SectionContent>
        </Section>
       
      </View>
    </View>
    </View>
    </ImageBackground>
    </Layout>
  );
}
const styles = StyleSheet.create({
    Precontainer: {
      flexDirection: "column",
      alignItems: 'center',
      backgroundColor: '#FFD700',
      borderRadius: 10,
    },
    container: {
      flex: 1,
      alignItems: "center", // Center horizontally
      justifyContent: "center", // Center vertically
      backgroundColor: "", // Background color for the entire screen
    },
    containerBTN: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      backgroundColor: '#EEEEEE',
    },
    backgroundImage: {
      flex: 1,
      resizeMode: "cover",
      justifyContent: "center",
      alignItems: "center",
    },
    containerEDT: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: '#EEEEEE',
      },
      card: {
      },
  });
