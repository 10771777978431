import React, { useEffect, useState } from "react";
import { View, StyleSheet, ScrollView, TouchableOpacity, ImageBackground, TextInput } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { supabase } from "../initSupabase";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Layout,
  Button,
  Text,
  TopNav,
  Section,
  SectionContent,
  useTheme,
  themeColor,
  SectionImage,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";




import { LogBox } from 'react-native';
import _, { set } from 'lodash';
import { useRoute, RouteProp } from "@react-navigation/native";

LogBox.ignoreLogs(['Warning:...']); // ignore specific logs
LogBox.ignoreAllLogs(); // ignore all logs
const _console = _.clone(console);
console.warn = message => {
if (message.indexOf('Setting a timer') <= -1) {
   _console.warn(message);
   }
};
export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "Cancellation">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [fetchError, setFetchError] = useState(null)
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<null | boolean>(null);

  const route = useRoute<RouteProp<MainStackParamList, "Cancellation">>();
  const { OrderID} = route.params;

  useEffect(() => {
		const session = supabase.auth.session();
		setSession(session);
		setUser(session ? true : false);
		const { data: authListener } = supabase.auth.onAuthStateChange(
			async (event, session) => {
				console.log(`Supabase auth event: ${event}`);
				setSession(session);
				setUser(session ? true : false);
			}
		);
		return () => {
			authListener!.unsubscribe();
		};
	}, [user]);
  
    const [orderID, setOrderID] = useState(null)
    const [Name, setName] = useState(null)
    const [Pnumber, setPNUMBER] = useState()
    const [Address, setAddress] = useState(null)
    const [Orders, setOrders] = useState(null)
    const [Status, setStatus] = useState(null)
    const [totalPayment, setTotalPayment] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [branch, setBranch] = useState(null)
    const [Reason, setReason] = useState(null)
    const [GcashRefNo, setGcashRefNo] = useState(null)

    useEffect(()=>{
        const fetchItem = async () => { 
        const {data, error} = await supabase
          .from('Orders')
          .select()
          .eq('id',OrderID)
          .single()
          if (error?.message === 'Record not found'){ 
            setFetchError(null)
            setName(null)
            setAddress(null)
         
          } 
          if (data){
            setOrderID(data.orderID)
            setName(data.name)
            setOrders(data.orders)
            setStatus(data.status)
            setTotalPayment(data.total_payment)
            setPaymentMethod(data.payment_method)
            setBranch(data.branch)
            setGcashRefNo(data.GcashRefNo)
            setFetchError(null)
            console.log(data)
          }
        }
        fetchItem()
        }, [OrderID])


        const Cancellation = async () => {
            setLoading(true)
            const { data, error } = await supabase
            .from('Orders')
            .update([{ status: "Cancelled"}])
            .eq('Cancellation', 'Eligible')
            if (error) {
              alert(error.message)
              setLoading(false)
              return
            }
            if(data){
              console.log(data)
              alert("Succesfull")
            }
            else{
              alert("Sorry the order has been Approved and Processing")
            }
            }
        
    
      
    
  return (
    <Layout>
      <TopNav
        middleContent="C A N C E L L A T I O N"
        leftContent={
            <Ionicons
              name="chevron-back"
              size={20}
              color={isDarkmode ? themeColor.white100 : themeColor.dark}
            />
          }
          leftAction={() => navigation.goBack()}
         
      />
      <View style={{flex: 1,}}>
              {/* <SectionContent>
                <Button
                leftContent={<Ionicons name="person" size={20}  color={isDarkmode ? themeColor.white100 : themeColor.dark} />}
                text="   Edit Profile"
                inverseColor="black"
                type="TouchableOpacity"
                color="#EEEEEE"
                onPress={() => {navigation.navigate("Edit_Profile");}}
                style={{marginTop: 10}}
                />

              </SectionContent> */}
              <SectionContent >
                   
                    <Section  > 
                        <SectionContent style={ styles.singleItem}>
                        <Text fontWeight="light" style={{ textAlign: "left" , marginBottom:5, marginTop:5,}}>  Order No. : {orderID}</Text>
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Name: {Name}</Text>
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Order : </Text> 
                        <Text fontWeight="light" style={{ width: "100%", textAlign: "center" , marginBottom:5, marginTop:5,}}>  {Orders} </Text>
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Branch: {branch}</Text>
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Total : ₱ {totalPayment}</Text>
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Payment Method: {paymentMethod}</Text>
                        <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5, marginTop:5,}}>  Gcash Ref. No. : {GcashRefNo}</Text>
                        
                        </SectionContent>
                        <Button 
                          text="Cancel"  
                          status="danger" 
                          color="#FFD700"
                          textStyle={{
                            color: "black",
                            fontWeight: "bold", // Set text to bold
                            textTransform: "uppercase", // Set text to uppercase
                            }}
                            style={{marginBottom:20,}} 
                            onPress={()=> Cancellation()} 
                        />
                    </Section>
                  
            </SectionContent>
        </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  singleItem: {
    flex: 1,
    alignItems: "center",
    alignContent: "center",
    alignSelf: "center",
    marginTop: 120,
    flexDirection: "column",
    marginBottom: 10,
    borderRadius: 10,
    borderWidth: 10,
    borderColor: "#FFD700",
    width: "90%",
    height: "90%",
  },
  });
