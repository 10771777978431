import React from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import SecondScreen from "../screens/SecondScreen";
import MainTabs from "./MainTabs";
import Cart from "../screens/Cart";
import CheckOut from "../screens/CheckOut";
import CartItem from "../screens/CartItem";
import EditProfile from "../screens/EditProfile";
import AddAddress from "../screens/AddAddress";
import Addresses from "../screens/Addresses";
import MyOrders from "../screens/MyOrders";
import Cancellation from "../screens/Cancellation";




const MainStack = createNativeStackNavigator();
const Main = (menus:any) => {
  return (
    <MainStack.Navigator
      screenOptions={{
        headerShown: false,
      }}
    >
      <MainStack.Screen name="MainTabs" component={MainTabs} />
      <MainStack.Screen name="SecondScreen" component={SecondScreen} />
      <MainStack.Screen name="Cart" component={Cart} />
      <MainStack.Screen name="CheckOut" component={CheckOut} />
      <MainStack.Screen name="CartItem" component={CartItem} initialParams={{ ItemID: menus.id }}/>
      <MainStack.Screen name="Edit_Profile" component={EditProfile} />
      <MainStack.Screen name="Add_Address" component={AddAddress} />
      <MainStack.Screen name="Addresses" component={Addresses} />
      <MainStack.Screen name="MyOrders" component={MyOrders} />
      <MainStack.Screen name="Cancellation" component={Cancellation} initialParams={{ OrderID: menus.id }}/>

    </MainStack.Navigator>
  );
};

export default Main;
