import React, { useEffect, useState } from "react";
import { ImageBackground,View,StyleSheet } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Layout,
  TopNav,
  Text,
  themeColor,
  useTheme,
  Section,
  SectionContent,
  TextInput,
  Button,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";
import { supabase } from "../initSupabase";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "Edit_Profile">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<null | boolean>(null);

  useEffect(() => {
		const session = supabase.auth.session();
		setSession(session);
		setUser(session ? true : false);
		const { data: authListener } = supabase.auth.onAuthStateChange(
			async (event, session) => {
				console.log(`Supabase auth event: ${event}`);
				setSession(session);
				setUser(session ? true : false);
			}
		);
		return () => {
			authListener!.unsubscribe();
		};
	}, [user]);

  const [USERNAME, setUSERNAME] = useState("")
  const [PNUMBER, setPNUNMER] = useState("")

  useEffect(()=>{
    const fetchProfile = async () => { 
    const {data, error} = await supabase
      .from('profiles')
      .select()
      .eq('email',session?.user?.email)
      .single()
      if (error?.message === 'Record not found'){ 
        setUSERNAME("")
        setPNUNMER("")
      } 
      if (data){
        setUSERNAME(data.username)
        setPNUNMER(data.P_NUMBER)
      }
    }
    fetchProfile()
    }, [session?.user?.email])

    const saveUser = async () => {
        setLoading(true)
        const { data,error } = await supabase
        .from('profiles')
        .insert([{ email:session?.user?.email, username:USERNAME, P_NUMBER:PNUMBER }])
      if (error) 
        setLoading(false)
        //alert("You already have a profile")
      if(data){
        console.log(data)
        alert("Succesfull")
      }
      }
      const savePNUMBER = async () => {
        setLoading(true)
        const { data,error } = await supabase
        .from('profiles')
        .update([{ P_NUMBER:PNUMBER,username:USERNAME }])
        .eq('email',session?.user?.email)
      if (error) alert(error.message)
        setLoading(false)
      if(data){
        console.log(data)
        alert("Succesfull")
      }
      }
      
  return (
    <Layout>
      <TopNav
        middleContent="E D I T  P R O F I L E"
        leftContent={
          <Ionicons
            name="chevron-back"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.dark}
          />
        }
        leftAction={() => navigation.goBack()}
      />
      <ImageBackground
        source={require("../../assets/images/seafoodtwo.jpg")}
        style={styles.backgroundImage}
      >
    <View style={styles.container}>
    <View style={styles.card}>
     <View style={{flex: 1,}}>
        {/* This text using ubuntu font */}

        <Section style={{ marginTop: 10}}>
          <SectionContent  style={styles.Postcontainer} >
          <Text fontWeight="bold"  size="xl">
            {USERNAME}  </Text>
            <Text fontWeight="regular"> 
            Personal Account</Text>
          </SectionContent>
          <SectionContent style={styles.containerEDT}>
          <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5}}>  Email:</Text>
          <TextInput 
                editable={false} selectTextOnFocus={false}
                placeholder="Enter your Full Name"
                value={session?.user?.email}
                
            />
          <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5,marginTop:5}}>  Name:</Text>
          <TextInput 
                placeholder="Enter your Full Name"
                value={USERNAME}
                onChangeText={(val) => setUSERNAME(val)}
            />
             <Text fontWeight="bold" style={{ textAlign: "left" , marginBottom:5 , marginTop:5}}>  Mobile Number:</Text>
              <TextInput 
                    placeholder="Enter your Mobile Number"
                    maxLength={11}
                    keyboardType="numeric"
                    value={PNUMBER}
                    onChangeText={(val) => setPNUNMER(val)}
                    
                />
             <Button
              style={{ marginTop: 5 }}
              text="SAVE"
              status="primary"
              size="md"
	            color="#000"
               textStyle={{
                color: "white",
                fontWeight: "bold", // Set text to bold
                textTransform: "uppercase", // Set text to uppercase
                  }}
              onPress={() => {saveUser(),savePNUMBER()}}
            />
          </SectionContent>
          
         
          
        </Section>
       
      </View>
      </View>
    </View>
    </ImageBackground>
    </Layout>
  );
}

const styles = StyleSheet.create({
    Postcontainer: {
      flex: 1,
      alignItems: "center", // Center horizontally
      justifyContent: "center", // Center vertically
      backgroundColor: "#FFD700"
      
    },
    container: {
      flex: 1,
      alignItems: "center", // Center horizontally
      justifyContent: "center", // Center vertically
    },
    backgroundImage: {
      flex: 1,
      resizeMode: "cover",
      justifyContent: "center",
      alignItems: "center",
    },
    containerEDT: {
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: "#FFD700"
  },
    card: {
        borderWidth: 10,
        borderColor: "#FFD700",
        borderRadius: 10, // Border radius for the card
        shadowColor: "black", // Shadow color
        width: "100%",
        shadowOffset: {
          width: 10, // Increase the width to make the card larger
          height: 5, // Increase the height to make the card larger
        },
        shadowOpacity: 0.25, // Shadow opacity
        shadowRadius: 5, // Shadow radius
        elevation: 5, // Android shadow elevation
        marginBottom: 10,
        padding: 20, // Increase the padding to make the card larger
        alignItems: "center", // Center card content horizontally
        backgroundColor: "#FF700",
      },
    });
