import React, { useEffect, useState } from "react";
import { View,StyleSheet, ImageBackground } from "react-native";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Layout,
  TopNav,
  Text,
  themeColor,
  useTheme,
  Section,
  SectionContent,
  TextInput,
  Button,
  Picker,
} from "react-native-rapi-ui";
import { Ionicons } from "@expo/vector-icons";
import { Session } from "@supabase/supabase-js";
import { supabase } from "../initSupabase";
import { RouteProp, useRoute } from "@react-navigation/native";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "Add_Address">) {
  const { isDarkmode, setTheme } = useTheme();
  const [loading, setLoading] = useState(true)
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<null | boolean>(null);

 

  useEffect(() => {
		const session = supabase.auth.session();
		setSession(session);
		setUser(session ? true : false);
		const { data: authListener } = supabase.auth.onAuthStateChange(
			async (event, session) => {
				console.log(`Supabase auth event: ${event}`);
				setSession(session);
				setUser(session ? true : false);
			}
		);
		return () => {
			authListener!.unsubscribe();
		};
	}, [user]);

  const [streetName, setStreetName] = useState("")
  const [BarangayName, setBarangayName] = useState("")
  const [City, setCity] = useState("")
  const [Province, setProvince] = useState("")

  useEffect(()=>{
    const fetchAddress = async () => { 
    const {data, error} = await supabase
      .from('userAddresses')
      .select()
      .eq('email',session?.user?.email)
      .single()
      if (error?.message === 'Record not found'){ 
        setStreetName("")
        setBarangayName("")
        setCity("")
        setProvince("")
      } 
      if (data){
        setStreetName(data.streetName)
        setBarangayName(data.BarangayName)
        setCity(data.City)
        setProvince(data.Province)
      }
    }
    fetchAddress()
    }, [session?.user?.email])

    

    const saveAddress = async () => {
        setLoading(true)
        const { data,error } = await supabase
        .from('userAddresses')
        .insert([{ email:session?.user?.email, adrs_Name:Adr_Name, street:streetName, barangay:BarangayName, city:City, province:Province }])
      if (error) alert(error.message)
        setLoading(false)
      if(data){
        console.log(data)
        alert("Address Saved")
      }
      }
      const [Adr_Name, setAdr_Name] = useState("Home");
        const items = [
        { label: 'Home', value: 'Home' },
        { label: 'Office', value: 'Office' },
        { label: 'Partner', value: 'Partner' },
        { label: 'Others', value: 'Others' },
    ];
  return (
    <Layout>
      <TopNav
        middleContent="A D D  H O M E  A D D R E S S"
        leftContent={
          <Ionicons
            name="chevron-back"
            size={20}
            color={isDarkmode ? themeColor.white100 : themeColor.dark}
          />
        }
        leftAction={() => navigation.goBack()}
       
      />
      <ImageBackground
        source={require("../../assets/images/seafoodtwo.jpg")}
        style={styles.backgroundImage}
      >
    <View style={styles.container}>
    <View style={styles.card}>
     <View style={{flex: 1,}}>
        {/* This text using ubuntu font */}

        <Section style={{ marginTop: 20 }}>
          <SectionContent  style={styles.Precontainer}>
          <Text fontWeight="bold" style={{ textAlign: "center" , marginBottom:5}}>  ADD YOUR CURRENT ADDRESS:</Text>
          </SectionContent>
          <SectionContent style={styles.containerEDT}>
            {/* <Picker  
                        style={{ marginBottom: 20 }}
                        items={items}
                        value={Adr_Name}
                        placeholder="Choose Address Name"
                        onValueChange={(val) => setAdr_Name(val)}
                        
                    /> */}
          {/* <TextInput 
                style={{ marginBottom: 10 }}
                placeholder="Enter Street Name"
                editable={false} selectTextOnFocus={false}
                value={Adr_Name}
                onChangeText={(val) => setAdr_Name(val)}
            /> */}

             <Text size='xl' style={{marginBottom:3}}>  {Adr_Name} Address</Text>
             <Text size='sm' style={{marginBottom:3}}>  Street: </Text>
          <TextInput 
                placeholder="Type Street Name"
                value={streetName}
                onChangeText={(val) => setStreetName(val)}
            />
            <Text size='sm' style={{marginBottom:3, marginTop:3}}>  Barangay: </Text>
             <TextInput 
                placeholder="Type Barangay Name"
                value={BarangayName}
                onChangeText={(val) => setBarangayName(val)}
            />
            <Text size='sm' style={{marginBottom:3, marginTop:3}}>  City: </Text>
             <TextInput 
                placeholder="Type City Name"
                value={City}
                onChangeText={(val) => setCity(val)}
            />
            <Text size='sm' style={{marginBottom:3, marginTop:3}}>  Province: </Text>
             <TextInput 
                placeholder="Type Province Name"
                value={Province}
                onChangeText={(val) => setProvince(val)}
            />

             <Button
              style={{ marginTop: 5 }}
              text="Save"
              status="primary"
              size="md"
		          color="white"
                                  textStyle={{
                                    color: "black",
                                    fontWeight: "bold", // Set text to bold
                                    textTransform: "uppercase", // Set text to uppercase
                                    }}
              
          
              onPress={() => saveAddress()}
            />
          </SectionContent>
        </Section>
       
      </View>
    </View>
    </View>
    </ImageBackground>
    </Layout>
  );
}
const styles = StyleSheet.create({
    Precontainer: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#ffd700',
      marginBottom: 10,
      borderRadius: 10,
    },
    container: {
      flex: 1,
      alignItems: "center", // Center horizontally
      justifyContent: "center", // Center vertically
      borderRadius: 10, 
    },
    containerBTN: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#ffd700',
      marginBottom: 10,
      borderRadius: 10, 
    },
    containerEDT: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '#ffd700',
        marginBottom: 10,
        borderRadius: 10, 
      },
      card: {
        borderWidth: 10,
        borderColor: "#FFD700",
        borderRadius: 10, 
        backgroundColor: "white",
        shadowColor: "black", 
        width: "90%",
        shadowOffset: {
          width: 10, 
          height: 5, 
        },
        shadowOpacity: 0.25, // Shadow opacity
        shadowRadius: 5, // Shadow radius
        elevation: 5, 
        marginBottom: 10,
        padding: 20, 
        alignItems: "center", 
      },
      backgroundImage: {
        flex: 1,
        resizeMode: "cover",
        justifyContent: "center",
        alignItems: "center",
      },
  });
